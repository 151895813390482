import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuIcon from '../../icons/MenuIcon';
import CloseIcon from '../../icons/CloseIcon';

const propTypes = {
  mobil: PropTypes.bool.isRequired,
  onChangeNaviIcon: PropTypes.func.isRequired,
};
const defaultProps = {};

export default function MobilNavigation(props) {
  const { mobil, onChangeNaviIcon } = props;

  return (
    <React.Fragment>
      <IconWrap onClick={onChangeNaviIcon}>
        {mobil
          ? <MenuIcon />
          : <CloseIcon />
        }
      </IconWrap>
    </React.Fragment>
  );
}

MobilNavigation.propTypes = propTypes;
MobilNavigation.defaultProps = defaultProps;

const IconWrap = styled.div`
  width: 38px;
  height: 36px;
  cursor: pointer;
  display: none;

  & svg {
    width: 36px;
    height: 36px;
  }

  @media(max-width: 1110px) {
    display: block;
  }
`;
