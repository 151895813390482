
const theme = {
  palette: {
    primary: {
      light: '#e41a1d',
      light2: '#ff5555',
      light3: '#ff9999',
      dark: '#273049',
      darker: '#1c2335',
      section: '#169fda',
    },
    secondary: {
      light: '#5cbce5',
      dark: '#39ade0',
      section: '#efefef',
    },
    section: {
      sales: '#11a0db',
    },
    common: {
      white: '#fff',
      black: '#000',
    },
    black: {
      light: '#666',
      light2: '#999',
    },
    red: {
      light: '#f55',
    },
  },
};

export default theme;
