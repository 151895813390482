import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/JHFinanace_logo.png';
import FooterNavi from '../../containers/FooterNavi';
import Partners from '../../components/Partners';
import city from '../../icons/domky2.png';

function Footer() {
  return (
    <React.Fragment>
      <Image src={city} />
      <FooterContent>
        <FooterHead>
          <PageLogo src={Logo} />
          <FooterNavi />
        </FooterHead>
        <PartnersBox>
          <h3>Partneři:</h3>
          <Partners />
        </PartnersBox>
        <h5>2019 &copy; JHavel Finance s.r.o.</h5>
      </FooterContent>
    </React.Fragment>
  );
}
const Image = styled.img`
  width: 100%;
  margin-bottom: -6px;
`;
const PartnersBox = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
`;

const FooterHead = styled.div`
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const PageLogo = styled.img`
  width: 270px;
  height: 100px;
  background: ${({ theme }) => theme.palette.common.white};
  padding: 10px;
  box-shadow: 1px 3px 6px ${({ theme }) => theme.palette.black.light};

  @media (max-width: 950px) {
    margin: 0 auto;
  }

  @media (max-width: 900px) {
    width: 220px;
    height: 84px;
  }
`;

const FooterContent = styled.div`
  width: 100%;
  padding: 24px;
  background: ${({ theme }) => theme.palette.primary.light};
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.white};

  h3 {
    margin-top: 0;
  }

  h5 {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
  }
`;

export default Footer;
