import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Logo from '../../assets/JHFinanace_logo.png';
import PhoneIcon from '../../icons/PhoneIcon';
import MobilNavigation from '../MobilNavigation';

const propTypes = {
  mobil: PropTypes.bool.isRequired,
  onChangeNaviIcon: PropTypes.func.isRequired,
};
const defaultProps = {};

function HeaderNavi(props) {
  const { onChangeNaviIcon, mobil } = props;

  return (
    <Header>
      <PageLogo src={Logo} />
      <TextWrap>
        <h3>Komplexní řešení financování potřeb</h3>
        <h3 style={{ paddingLeft: 12 }}>společenství vlastníků a bytových družstev</h3>
      </TextWrap>
      <ContactWrap>
        <IconWraper>
          <PhoneIcon />
        </IconWraper>
        <a href='tel:+420777881211'>Volejte: 777 881 211</a>
      </ContactWrap>
      <MobilNavigation onChangeNaviIcon={onChangeNaviIcon} mobil={mobil} />
    </Header>
  );
}
const IconWraper = styled.div`
  border: 1px solid black;
  border-radius: 1000px;
  padding: 6px;
  width: 38px;
  height: 38px;
  margin-right: 8px;
`;
const ContactWrap = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    color: #000;
  }

  @media(max-width: 550px) {
    display: none;
  }
`;

const TextWrap = styled.div`
  h2 {
    margin: 6px;
  }

  @media(max-width: 1110px) {
    font-size: 14px;
  }

  @media(max-width: 1020px) {
    font-size: 12px;
  }
  @media(max-width: 950px) {
    font-size: 10px;
  }
  @media(max-width: 820px) {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1800px;
  margin: 0 auto;
`;

const PageLogo = styled.img`
  width: 250px;
  height: 80px

  @media(max-width: 900px) {
    width: 200px;
    height: 64px;
  }
`;

HeaderNavi.propTypes = propTypes;
HeaderNavi.defaultProps = defaultProps;

export default HeaderNavi;
