import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';

export default function FooterNavi() {
  return (
    <PageWraper>
      <TextWrap>
        <h3>Nejžádanější produkty</h3>
        <LinkWrap>
          <Link to='/uvery'>Úvěry</Link>
          <Link to='/sluzby'>Hlídač fixace</Link>
        </LinkWrap>
      </TextWrap>
      <TextWrap>
        <h3>Podpora</h3>
        <LinkWrap>
          <Link to='/castedotazy'>Časté dotazy</Link>
          <Link to='/documents'>Dokumenty</Link>
        </LinkWrap>
      </TextWrap>
      <TextWrap>
        <h3>JHavel Finance</h3>
        <LinkWrap>
          <Link to='/about'>O nás</Link>
          <Link to='/kariera'>Kariéra</Link>
          <Link to='/kontakt'>Kontakt</Link>
        </LinkWrap>
      </TextWrap>
    </PageWraper>
  );
}

const PageWraper = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  padding: 0 12px;

  @media(max-width: 950px) {
    width: 100%;
    padding: 24px 0 12px 0;
  }

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;

const LinkWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextWrap = styled.div`

  a, a:link {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.white};
    transition: all .4s ease;
    margin-bottom: 8px;
  }
  a:hover {
    text-decoration: underline;
  }

  @media(max-width: 800px) {
    padding: 12px 0;
  }
`;
