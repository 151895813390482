const getPartners = [
  {
    id: '1',
    name: 'Raiffeisen stavební spořitelna, a.s.',
    adress: 'Koněvova 2747/99, 130 45 Praha 3',
    ico: '49241257',
    web: 'www.rsts.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/raiffeisen.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/raiffeisen.png',
  },
  {
    id: '2',
    name: 'Unicredit Bank Czech Republic and Slovakia, a.s.',
    adress: 'Želetavská 1525/1, Michle, 14092 Praha 4',
    ico: '64948242',
    web: 'www.unicreditbank.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/unicredit.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/unicredit.png',
  },
  {
    id: '3',
    name: 'Moneta Stavební Spořitelna, a.s.',
    adress: 'Vyskočilova 1442/1b, Michle, 140 00 Praha 4',
    ico: '47115289',
    web: 'www.moneta.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/wustenrot.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/wustenrot.png',
  },
  {
    id: '4',
    name: 'Stavební spořitelna České spořitelny, a.s.',
    adress: 'Vinohradská 180/1632, 130 11 Praha 3',
    ico: '60197609',
    web: 'www.burinka.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/stavebniSPORITELNA.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/sscs.png',
  },
  {
    id: '5',
    name: 'Česká spořitelna, a.s.',
    adress: 'Olbrachtova 1929/62, 140 00 Praha 4',
    ico: '45244782',
    web: 'www.csas.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/ceskaSPORITELNA.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/ceskasporitelna.png',
  },
  {
    id: '6',
    name: 'Komerční banka, a.s.',
    adress: 'Na Příkopě 33 čp. 969, 114 07 Praha 1',
    ico: '45317054',
    web: 'www.kb.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/kb.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/kb.png',
  },
  {
    id: '7',
    name: 'Československá obchodní banka, a.s.',
    adress: 'Radlická 333/150, 150 57 Praha 5',
    ico: '1350',
    web: 'www.csob.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/csob.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/csob.png',
  },
  {
    id: '8',
    name: 'Moneta Money bank, a.s.',
    adress: 'Vyskočilova 1442/1b, Michle, 140 00 Praha 4',
    ico: '25672720',
    web: 'www.moneta.cz',
    img: 'https://www.jhavelfinance.cz/public/partners/wustenrot.jpg',
    icon: 'https://www.jhavelfinance.cz/public/partnersIcon/wustenrotHypotecni.png',
  },
  // {
  //   id: '10',
  //   name: 'Modrá Pyramida stavební spořitelna, a.s.',
  //   adress: 'Bělehradská 128, čp. 222, 12021 Praha 2',
  //   ico: '60192852',
  //   web: 'www.modrapyramida.cz',
  //   img: 'https://www.jhavelfinance.cz/public/partners/modraPYRAMIDA.jpg',
  //   icon: 'https://www.jhavelfinance.cz/public/partnersIcon/modrapyramida.png',
  // },
];

export default getPartners;
