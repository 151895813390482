
import universal, { setHasBabelPlugin } from '/Users/lukas/Source/my-projects/havelweb/node_modules/react-static/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.js'), universalOptions)
const t_1 = universal(import('../src/pages/about.js'), universalOptions)
const t_2 = universal(import('../src/pages/castedotazy.js'), universalOptions)
const t_3 = universal(import('../src/pages/documents.js'), universalOptions)
const t_4 = universal(import('../src/pages/hlidac.js'), universalOptions)
const t_5 = universal(import('../src/pages/index.js'), universalOptions)
const t_6 = universal(import('../src/pages/kariera.js'), universalOptions)
const t_7 = universal(import('../src/pages/kontakt.js'), universalOptions)
const t_8 = universal(import('../src/pages/konzultace.js'), universalOptions)
const t_9 = universal(import('../src/pages/objednatkrefinancovani.js'), universalOptions)
const t_10 = universal(import('../src/pages/objednatkuveru.js'), universalOptions)
const t_11 = universal(import('../src/pages/ok.js'), universalOptions)
const t_12 = universal(import('../src/pages/partners.js'), universalOptions)
const t_13 = universal(import('../src/pages/reference.js'), universalOptions)
const t_14 = universal(import('../src/pages/refinancovani.js'), universalOptions)
const t_15 = universal(import('../src/pages/sluzby.js'), universalOptions)
const t_16 = universal(import('../src/pages/uvery.js'), universalOptions)


// Template Map
export default {
  '../src/pages/404.js': t_0,
'../src/pages/about.js': t_1,
'../src/pages/castedotazy.js': t_2,
'../src/pages/documents.js': t_3,
'../src/pages/hlidac.js': t_4,
'../src/pages/index.js': t_5,
'../src/pages/kariera.js': t_6,
'../src/pages/kontakt.js': t_7,
'../src/pages/konzultace.js': t_8,
'../src/pages/objednatkrefinancovani.js': t_9,
'../src/pages/objednatkuveru.js': t_10,
'../src/pages/ok.js': t_11,
'../src/pages/partners.js': t_12,
'../src/pages/reference.js': t_13,
'../src/pages/refinancovani.js': t_14,
'../src/pages/sluzby.js': t_15,
'../src/pages/uvery.js': t_16
}

export const notFoundTemplate = "../src/pages/404.js"
