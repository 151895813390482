import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
  mobil: PropTypes.bool.isRequired,
};

export default function MenuLinksDesktop(props) {
  const { mobil } = props;

  return (
    <NaviWraper style={{ display: mobil ? 'flex' : 'none' }}>
      <Link to='/'>Hlavní strana</Link>
      <Link to='/uvery'>Úvěry</Link>
      <Link to='/refinancovani'>Refinancování</Link>
      <Link to='/sluzby'>Služby pro bytové domy</Link>
      <Link to='/reference'>Reference</Link>
      <Link to='/partners'>Partneři</Link>
      <Link to='/kontakt'>Kontakt</Link>
    </NaviWraper>
  );
}

MenuLinksDesktop.propTypes = propTypes;

const NaviWraper = styled.div`
  width: 100%;
  max-width: 1800px
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;

  @media(max-width: 1110px) {
    display: none !important;
  }

  a, a:link {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 600;
    font-size: 20px;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
    margin-bottom: -2px;
    transition: all .3s ease;
  }
  a:hover {
    border-bottom: 2px solid ${({ theme }) => theme.palette.common.black};
  }
  a:focus {
    border-bottom: 2px solid ${({ theme }) => theme.palette.common.black};
  }
`;
