import React from 'react';
import { Root, Routes } from 'react-static';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import GlobalStyle from './style/GlobalStyle';
import Navigation from './layout/Navigation';
import Footer from './layout/Footer';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Root>
        <GlobalStyle />
        <Navigation />
        <div className='content'>
          <Routes />
        </div>
        <Footer />
      </Root>
    </ThemeProvider>
  );
}

export default App;
