// Imports


// Plugins
const plugins = [{
  location: "/Users/lukas/Source/my-projects/havelweb/node_modules/react-static-plugin-styled-components",
  plugins: [],
  hooks: {}
},
{
  location: "/Users/lukas/Source/my-projects/havelweb",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins