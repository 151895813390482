import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import PhoneIcon from '../../icons/PhoneIcon';

const propTypes = {
  mobil: PropTypes.bool.isRequired,
  onChangeNaviIcon: PropTypes.func.isRequired,
};

export default function MenuLinksMobile(props) {
  const { mobil, onChangeNaviIcon } = props;

  return (
    <PageWrapper style={{ display: mobil ? 'none' : 'flex' }}>
      <ContactWrap>
        <IconWrapper>
          <PhoneIcon />
        </IconWrapper>
        <p>
          Volejte:
          {' '}
          <a href='tel:777 881 211'>777 881 211</a>
        </p>
      </ContactWrap>
      <NaviWraper>
        <Link to='/' onClick={onChangeNaviIcon}>Hlavní strana</Link>
        <Link to='/uvery' onClick={onChangeNaviIcon}>Úvěry</Link>
        <Link to='/refinancovani' onClick={onChangeNaviIcon}>Refinancování</Link>
        <Link to='/sluzby' onClick={onChangeNaviIcon}>Služby pro bytové domy</Link>
        <Link to='/reference' onClick={onChangeNaviIcon}>Reference</Link>
        <Link to='/partners' onClick={onChangeNaviIcon}>Partneři</Link>
        <Link to='/kontakt' onClick={onChangeNaviIcon}>Kontakt</Link>
      </NaviWraper>
    </PageWrapper>
  );
}

MenuLinksMobile.propTypes = propTypes;

const PageWrapper = styled.div`
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  border: 1px solid black;
  border-radius: 1000px;
  padding: 6px;
  width: 38px;
  height: 38px;
  margin-right: 8px;
`;
const ContactWrap = styled.div`
  display: none;
  padding: 6px 0;

  @media(max-width: 550px) {
    margin-top: 12px;
    display: flex;
    align-items: center;
  }

  a, a:link {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 600;
  }
`;

const NaviWraper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;

  @media(max-width: 550px) {
    align-items: flex-start;
    padding-top: 0;
  }

  @media(max-width: 450px) {
    align-items: flex-start;
  }

  a, a:link {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.black};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
    transition: all .3s ease;
    margin: 2px 0 6px 0;

    @media(max-width: 550px) {
      margin: 12px 0 6px 0;
    }
  }
  a:hover {
    border-bottom: 2px solid ${({ theme }) => theme.palette.common.black};
  }
`;
