import React from 'react';
import styled from 'styled-components';
import enhanceWithClickOutside from 'react-click-outside';
//
import HeaderNavi from '../../containers/HeaderNavi';
import MenuLinksDesktop from '../../containers/MenuLinksDesktop';
import MenuLinksMobile from '../../containers/MenuLinksMobile';
import NaviScroll from '../../components/NaviScroll';

class Navigation extends React.Component {
  state = {
    mobil: true,
  };

  handleChangeNaviIcon = () => {
    const { mobil } = this.state;
    this.setState({ mobil: !mobil });
  }

  handleClickOutside() {
    this.toggle();
  }

  toggle() {
    this.setState({ mobil: true });
  }

  render() {
    const { mobil } = this.state;

    return (
      <Wrapper>
        <HeaderNavi mobil={mobil} onChangeNaviIcon={this.handleChangeNaviIcon} />
        <MenuLinksDesktop mobil={mobil} />
        <MenuLinksMobile mobil={mobil} onChangeNaviIcon={this.handleChangeNaviIcon} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  border-top: 6px solid red;
  padding: 8px 24px;
  box-shadow: 1px 3px 6px rgba(0,0,0,.3);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: ${({ theme }) => theme.palette.common.white};
`;

export default enhanceWithClickOutside(Navigation);
