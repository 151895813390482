import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default class NavigationScroll extends React.Component {
  state = {
    isTop: true,
  };

  componentDidMount = () => {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  render() {
    const { menuContent, stickyMenu } = this.props;
    const { isTop } = this.state;

    return (
      <PageContainer isTop={isTop} className={`${isTop ? 'menu' : 'sticky'} navigation`}>
        {menuContent && stickyMenu && isTop ? menuContent : stickyMenu}
      </PageContainer>
    );
  }
}

NavigationScroll.propTypes = {
  menuContent: PropTypes.node,
  stickyMenu: PropTypes.node,
};
NavigationScroll.defaultProps = {
  menuContent: <h1>menu</h1>,
  stickyMenu: <h1>druhe menu</h1>,
};

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  box-shadow: 0 1px 6px rgba(0,0,0,.4);
`;
