import React from 'react';
import styled from 'styled-components';
import getPartners from '../../api/getPartners';

export default function Partners() {
  return (
    <Wrap>
      {getPartners.map((i) => (
        <Image src={i.icon} key={i.id} />
      ))}
    </Wrap>
  );
}

const Image = styled.img``;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  flex-flow: row wrap;

  & img {
    width: 80px;
    height: 40px;
    background: ${({ theme }) => theme.palette.common.white};
    margin: 0 2px;
    border-radius: 3px;
    box-shadow: 1px 3px 6px ${({ theme }) => theme.palette.black.light};
    margin-bottom: 12px;
  }
`;
